<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-12">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <Avatar
                      size="large"
                      class="primary"
                      icon="pi pi-user-plus"
                    />
                  </div>
                  <div class="ml-2">
                    <div class="text-2xl text-primary font-bold">
                      Add New User
                    </div>
                    <div class="text-sm text-500">
                      Create new user account
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr />
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-7 sm:col-12 comp-grid">
            <div class="">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startSaveRecord()"
                  @reset="resetForm"
                >
                  <div class="grid">
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Name</div>
                      <div id="ctrl-name-holder">
                        <InputText
                          ref="ctrlname"
                          v-model.trim="formData.name"
                          label="Name"
                          type="text"
                          placeholder="Enter Name"
                          class="w-full"
                          attern="^[A-Za-z-', ]+$"
                          :class="getErrorClass('name')"
                        >
                        </InputText>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Username *</div>
                      <div id="ctrl-username-holder">
                        <check-duplicate
                          v-model="formData.username"
                          check-path="components_data/administrators_username_exist/"
                          v-slot="checker"
                        >
                          <InputText
                            ref="ctrlusername"
                            @blur="checker.check"
                            :loading="checker.loading"
                            v-model.trim="formData.username"
                            label="Username"
                            type="text"
                            placeholder="Enter Username"
                            class="w-full"
                            pattern="^[0-9A-Za-z-_@.]+$"
                            :class="getErrorClass('username')"
                          >
                          </InputText>
                          <small
                            v-if="!checker.loading && checker.exist"
                            class="p-error"
                          >
                            Username has already been used</small
                          >
                          <small v-if="checker.loading" class="text-500"
                            >Checking...</small
                          >
                        </check-duplicate>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Email *</div>
                      <div id="ctrl-email-holder">
                        <check-duplicate
                          v-model="formData.email"
                          check-path="components_data/administrators_email_exist/"
                          v-slot="checker"
                        >
                          <InputText
                            ref="ctrlemail"
                            @blur="checker.check"
                            :loading="checker.loading"
                            v-model.trim="formData.email"
                            label="Email"
                            type="email"
                            placeholder="Enter Email"
                            class="w-full"
                            maxLength="40"
                            :class="getErrorClass('email')"
                          >
                          </InputText>
                          <small
                            v-if="!checker.loading && checker.exist"
                            class="p-error"
                          >
                            Email has already been used</small
                          >
                          <small v-if="checker.loading" class="text-500"
                            >Checking...</small
                          >
                        </check-duplicate>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Ghana Card Number *</div>
                      <div class="">
                        <InputMask
                            ref="ctrlghana_card"
                            label="Ghana Card Number"
                            type="text"
                            inputClass="w-full"
                            placeholder="GHA-000000000-0"
                            v-model.trim="formData.ghana_card"
                            class="w-full"
                            mask="aaa-999999999-9"
                            style="text-transform: uppercase"
                            :class="getErrorClass('ghana_card')"
                          >
                        </InputMask>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Account Type *</div>
                      <div id="ctrl-user_type-holder">
                        <Dropdown
                          class="w-full"
                          :class="getErrorClass('user_type')"
                          optionLabel="label"
                          optionValue="value"
                          ref="ctrluser_type"
                          v-model="formData.user_type"
                          :options="$menus.account_typeItems"
                          label="Account Type"
                          placeholder="Select ..."
                        >
                        </Dropdown>
                      </div>
                    </div>
                    <div class="col-12 md:col-6">
                      <div class="mb-2 font-bold text-sm">Account Status *</div>
                      <div id="ctrl-account_status-holder">
                        <Dropdown
                          class="w-full"
                          :class="getErrorClass('account_status')"
                          optionLabel="label"
                          optionValue="value"
                          ref="ctrlaccount_status"
                          v-model="formData.account_status"
                          :options="$menus.account_statusItems"
                          label="Account Status"
                          placeholder="Select ..."
                        >
                        </Dropdown>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="mb-2 font-bold text-sm">Photo *</div>
                      <div class="mb-3">
                          <Uploader
                            :class="getErrorClass('photo')"
                            :auto="true"
                            :fileLimit="1"
                            :maxFileSize="3000000"
                            accept=".jpg,.png,.gif,.jpeg"
                            :multiple="false"
                            style="width: 100%"
                            label="Choose photo here"
                            upload-path="s3uploader/upload/photo"
                            v-model="formData.photo"
                          ></Uploader>
                        </div>
                    </div>
                  </div>
                  <div v-if="showSubmitButton" class="text-center my-3">
                    <Button
                      class="p-button-primary"
                      type="submit"
                      label="Submit"
                      icon="pi pi-send"
                      :loading="saving"
                    />
                  </div>
                </form>
                <slot :submit="submit" :saving="saving"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addAdministratorsPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "administrators",
    },
    routeName: {
      type: String,
      default: "administratorsadd",
    },
    apiPath: {
      type: String,
      default: "administrators/add",
    },
  },
  data() {
    return {
      formData: {
        username: "",
        user_type: "",
        email: "",
        name: "",
        account_status: "",
        ghana_card: "",
        photo: "",
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add New Administrators";
      },
    },
  },
  validations() {
    let formData = {
      username: { required },
      user_type: { required },
      email: { required, email },
      name: {},
      account_status: { required },
      ghana_card: { required },
      photo: { required },
    };
    return { formData };
  },
  methods: {
    ...mapActions("administrators", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["administrator_id"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          if (this.redirect) this.navigateTo(`/administrators`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    resetForm() {
      this.formData = { username: "", email: "", name: "", account_status: "" };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
